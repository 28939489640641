<template>
  <div>
    <div v-if="loading" class="py-9">
      <v-progress-linear height="2" indeterminate/>
    </div>
    <v-form v-else ref="saveRelevancy" lazy-validation>
      <v-row class="mb-2">
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'typeOfGoods'|localize}}</h6>
          <v-select
            :background-color="type_of_goods.length ? 'serenade' : ''"
            v-model="type_of_goods"
            :items="goodTypesList"
            item-text="value"
            item-value="key"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            :rules="[validationRules.requiredArray]"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.value }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span>
                <span v-if="index > 0" class="mr-1">, </span>
                {{item.value}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{ 'deliveryTerms'|localize }}</h6>
          <v-select
            :background-color="delivery_terms.length ? 'serenade' : ''"
            v-model="delivery_terms"
            :items="deliveryTerms"
            item-text="text"
            item-value="value"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            :rules="[validationRules.requiredArray]"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span>
                <span v-if="index > 0" class="mr-1">, </span>
                {{item.value}}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6">
          <div class="d-flex align-center mb-2">
            <h6 class="text-subtitle-1">{{ 'fromToPresets'|localize }} <span style="font-weight: normal">({{ 'optional'|localize }})</span></h6>
            <v-tooltip top color="black" content-class="px-2">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" color='silver-chalice' class="ml-2">mdi-information
                </v-icon>
              </template>
              <template>
                <div class="py-1">
                  <ul class="px-0" style="list-style: none">
                    <li><strong>{{ 'domestic'|localize }}</strong> - {{ 'domesticNote'|localize }}</li>
                    <li><strong>{{ 'cross'|localize }}</strong> - {{ 'crossNote'|localize }}</li>
                    <li><strong>{{ 'import'|localize }}</strong> - {{ 'importNote'|localize }}</li>
                    <li><strong>{{ 'importEurope'|localize }}</strong> - {{ 'importEuropeNote'|localize }}</li>
                    <li><strong>{{ 'export'|localize }}</strong> - {{ 'exportNote'|localize }}</li>
                    <li><strong>{{ 'exportEurope'|localize }}</strong> - {{ 'exportEuropeNote'|localize }}</li>
                    <li><strong>{{ 'all'|localize }}</strong> - {{ 'anyNote'|localize }}</li>
                  </ul>
                </div>
              </template>
            </v-tooltip>
          </div>
          <v-select
            :background-color="selectedPreset ? 'serenade' : ''"
            v-model="selectedPreset"
            :items="countryPresets"
            item-text="name"
            item-value="key"
            dense
            clearable
            outlined
            :disabled="saveLoading"
            @change="presetHandler"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title><b>{{ item.name }}</b> ({{ item.description }})</v-list-item-title>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'regionFrom'|localize}} <span style="font-weight: normal">({{ 'optional'|localize }})</span></h6>
          <v-select
            :background-color="region_from.length ? 'serenade' : ''"
            v-model="region_from"
            :items="REGIONS"
            :item-text="item => `${item.value}`"
            :item-value="item => item.key"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            @change="regionHandler('region_from')"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.value }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span>
                <span v-if="index > 0" class="mr-1">, </span>
                {{ item.value }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'countryFrom'|localize}}</h6>
          <v-autocomplete
            :background-color="country_from.length ? 'serenade' : ''"
            v-model="country_from"
            :items="COUNTRY_LIST"
            :item-text="item => `${item.name} ${item.code}`"
            :item-value="item => `${item.code}`"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            :rules="[validationRules.requiredArray]"
            @change="clearFromPresets"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index <= 3">
                <span v-if="index > 0">, </span>
                {{ item.name }}
              </span>
              <span v-if="index === country_from.length - 1" class="mr-1" />
              <span v-if="index === 4" class="grey--text text-caption mx-1">+{{ country_from.length - 4 }}</span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'regionTo'|localize}} <span style="font-weight: normal">({{ 'optional'|localize }})</span></h6>
          <v-select
            :background-color="region_to.length ? 'serenade' : ''"
            v-model="region_to"
            :items="REGIONS"
            :item-text="item => `${item.value}`"
            :item-value="item => item.key"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            @change="regionHandler('region_to')"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.value }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span>
                <span v-if="index > 0" class="mr-1">, </span>
                {{ item.value }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'countryTo'|localize}}</h6>
          <v-autocomplete
            :background-color="country_to.length ? 'serenade' : ''"
            v-model="country_to"
            :items="COUNTRY_LIST"
            :item-text="item => `${item.name} ${item.code}`"
            :item-value="item => `${item.code}`"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            :rules="[validationRules.requiredArray]"
            @change="clearToPresets"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index <= 3">
                <span v-if="index > 0">, </span>
                {{ item.name }}
              </span>
              <span v-if="index === country_to.length - 1" class="mr-1" />
              <span v-if="index === 4" class="grey--text text-caption mx-1">+{{ country_to.length - 4 }}</span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'specialRequirements'|localize}}</h6>
          <v-select
            :background-color="special_requirements.length ? 'serenade' : ''"
            v-model="special_requirements"
            :items="requirementsList"
            :item-text="item => `${item.value}`"
            :item-value="item => `${item.key}`"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            :rules="[validationRules.requiredArray]"
          >
            <template v-slot:item="{ item, on }">
              <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
              <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <div class="d-flex align-center">
                <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                <v-list-item-title class="mr-2">{{ item['key']|localize }}</v-list-item-title>
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <h6 class="text-subtitle-1 mb-2">{{'receiver'|localize}}</h6>
          <v-select
            :background-color="receiver.length ? 'serenade' : ''"
            v-model="receiver"
            :items="receiverList"
            item-text="value"
            item-value="key"
            dense
            clearable
            outlined
            multiple
            :disabled="saveLoading || disabled"
            :rules="[validationRules.requiredArray]"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item.value }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span>
                <span v-if="index > 0">, </span>
                {{item.value}}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6" class="ml-auto">
          <h6 class="text-subtitle-1 mb-2">{{ 'blockedClients'|localize }}</h6>
          <v-autocomplete
            :background-color="blocked_clients.length ? 'serenade' : ''"
            v-model="blocked_clients"
            :items="clients"
            :item-text="item => `${item.name} ${item.id} ${item.email}`"
            item-value="user_id"
            dense
            clearable
            outlined
            multiple
            :loading="clientsLoading"
            :disabled="saveLoading || clientsLoading"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title><strong>{{ item.name }}</strong> <i>(ID:{{ item.id }}, {{ item.email }})</i></v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <span v-if="index <= 1">
                <span v-if="index > 0">, </span>
                {{ item.name }}
              </span>
              <span v-if="index === blocked_clients.length - 1" class="mr-1"/>
              <span v-if="index === 2" class="grey--text text-caption mx-1">+{{ blocked_clients.length - 2 }}</span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="!disabled">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            rounded
            depressed
            color="primary"
            :loading="saveLoading"
            :disabled="saveLoading"
            @click="saveRelevancy"
          > {{ 'save'|localize }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import localize from '@/filters/localize';
  import validationRules from '../helpers/validationRules';
  import methods from '@/helpers/methods';
  import api from '@/api/api';
  import { TRANSPORT_ICONS } from '@/enums/transportMode';
  import { COUNTRY_LIST } from '@/enums/countryList';
  import { REGIONS } from '@/enums/regions';
  import { PRESETS } from '@/enums/presets';
  import { SPECIAL_REQUIREMENTS_COLORS, SPECIAL_REQUIREMENTS_ICONS } from '@/enums/specialRequirements';

  export default {
    name: 'CompanyRelevancy',
    props: ['company_id', 'disabled'],

    data() {
      return {
        loading: true,
        saveLoading: false,
        validationRules: validationRules,
        TRANSPORT_ICONS,
        COUNTRY_LIST,
        REGIONS,
        selectedPreset: '',
        region_from: [],
        region_to: [],
        country_from: [],
        country_to: [],
        type_of_goods: [],
        delivery_terms: [],
        special_requirements: ['standard'],
        receiver: [],
        clientsLoading: false,
        clients: [],
        blocked_clients: [],
      };
    },

    mounted() {
      this.getRelevancy();
      this.getClients();
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      goodTypesList() {
        return this.$store.getters.goodTypesSorted.all;
      },
      requirementsList() {
        return this.$store.getters.requirementsList;
      },
      receiverList() {
        return this.$store.getters.receiverList;
      },
      deliveryTerms() {
        return this.$store.getters.deliveryTerms;
      },
      countryPresets() {
        return this.$store.getters.countryPresets;
      }
    },
    methods: {
      presetHandler() {
        this.region_to = [];
        this.region_from = [];
        switch (this.selectedPreset) {
          case PRESETS.DOMESTIC:
            this.country_from = ['SE'];
            this.country_to = ['SE'];
            break;
          case PRESETS.CROSS:
            this.country_from = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            this.country_to = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            break;
          case PRESETS.IMPORT:
            this.country_from = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            this.country_to = ['SE'];
            break;
          case PRESETS.IMPORT_EUROPE:
            this.country_from = this.COUNTRY_LIST.filter(item => item.region_code === 150).map(item => item.code);
            this.country_to = ['SE'];
            break;
          case PRESETS.EXPORT:
            this.country_from = ['SE'];
            this.country_to = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            break;
          case PRESETS.EXPORT_EUROPE:
            this.country_from = ['SE'];
            this.country_to = this.COUNTRY_LIST.filter(item => item.region_code === 150).map(item => item.code);
            break;
          case PRESETS.ALL:
            this.country_from = this.COUNTRY_LIST.map(item => item.code);
            this.country_to = this.COUNTRY_LIST.map(item => item.code);
            break;
          default:
            this.country_from = [];
            this.country_to = [];
        }
      },
      clearFromPresets() {
        this.region_from = [];
        this.selectedPreset = '';
      },
      clearToPresets() {
        this.region_to = [];
        this.selectedPreset = '';
      },
      regionHandler(region) {
        this.selectedPreset = '';
        let localCountries = (this[region].length) ? [...this.filter_countries_by_region(region).map(item => item.code)] : [];
        if (region === 'region_from') {
          this.country_from = localCountries;
        } else {
          this.country_to = localCountries;
        }
      },
      filter_countries_by_region(region) {
        let localCountries = [];
        this[region].forEach(element => {
          localCountries = localCountries.concat(this.COUNTRY_LIST.filter(item => item.region_code === element));
        });
        return (localCountries.length) ? localCountries : [];
      },
      setRelevancy(relevancyData) {
        relevancyData.type_of_goods && (this.type_of_goods = relevancyData.type_of_goods);
        relevancyData.delivery_terms && (this.delivery_terms = relevancyData.delivery_terms);
        relevancyData.country_from && (this.country_from = relevancyData.country_from.map(item => item.code));
        relevancyData.country_to && (this.country_to = relevancyData.country_to.map(item => item.code));
        relevancyData.special_requirements && (this.special_requirements = relevancyData.special_requirements);
        relevancyData.receiver && (this.receiver = relevancyData.receiver);
        relevancyData.blocked_clients && (this.blocked_clients = relevancyData.blocked_clients);
      },
      queryData() {
        let queryData = {};
        queryData.company_id = this.company_id;
        queryData.type_of_goods = JSON.stringify(this.type_of_goods);
        queryData.delivery_terms = JSON.stringify(this.delivery_terms);
        queryData.special_requirements = JSON.stringify(this.special_requirements);
        queryData.receiver = JSON.stringify(this.receiver);
        queryData.blocked_clients = JSON.stringify(this.blocked_clients);
        queryData.country_from = JSON.stringify(this.country_from.map(countryCode => {
          return {code: countryCode, name: COUNTRY_LIST.filter(item => item.code === countryCode)[0].name};
        }));
        queryData.country_to = JSON.stringify(this.country_to.map(countryCode => {
          return {code: countryCode, name: COUNTRY_LIST.filter(item => item.code === countryCode)[0].name};
        }));
        return queryData;
      },
      async getRelevancy() {
        try {
          this.loading = true;
          let relevancyData = null;
          if (this.disabled) {
            relevancyData = await api.getCompanyRelevancy(this.company_id);
          } else {
            relevancyData = await api.getCompanyRelevancyAdmin(this.company_id);
          }
          this.setRelevancy(relevancyData.data);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      async saveRelevancy() {
        if (this.$refs.saveRelevancy.validate()) {
          try {
            this.saveLoading = true;
            await api.saveCompanyRelevancy(this.queryData());
            this.saveLoading = false;
            methods.showGlobalSnackbar('success', localize('done'));
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      async getClients () {
        try {
          this.clientsLoading = true;
          let clientsData = await api.getClients({ paginate: 0 });
          this.clients = clientsData.data.data;
        } finally {
          this.clientsLoading = false;
        }
      },
    },
  }
</script>
